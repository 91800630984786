import React from "react";
import PropTypes from "prop-types";

import img1 from "../images/hd-6/(1).jpg";
import img2 from "../images/hd-6/(2).jpg";
import img3 from "../images/hd-6/(3).jpg";
import img4 from "../images/hd-6/(4).jpg";
import img5 from "../images/hd-6/(5).jpg";
import img6 from "../images/hd-6/(6).jpg";
import img7 from "../images/hd-6/(7).jpg";
import img8 from "../images/hd-6/(8).jpg";

function Image({ source }) {
  return (
    <img
      className="w-full md:w-8/12 lg:w-6/12 h-auto"
      src={source}
      alt="restaurant menu"
    ></img>
  );
}

function Menu() {
  const images = [img1, img2, img3, img4, img5, img6, img7, img8];
  return (
    <div className="max-w-screen h-auto ">
      <h1 className="px-2 text-3xl sm:text-4xl lg:text-5xl leading-none font-bold text-pink-600 tracking-tight mb-2 mt-4 text-center">
        Highway Delhi-6 Restro and Cafe
      </h1>
      <h2 className="text-center text-2xl sm:text-3xl lg:text-4xl leading-none font-bold text-pink-500 tracking-tight mb-8">
        COVID-19 Digital Menu
      </h2>
      <div className="flex gap-4 align-center justify-center mb-8">
        <a
          href="http://wa.me/917240643002"
          className="rounded-lg w-auto px-4 py-2  text-white  bg-gray-900 hover:shadow-xl font-semibold transition-shadow duration-500"
        >
          Order on Whatsapp
        </a>

        <a
          href="tel: +917240643002"
          className="rounded-lg w-auto px-4 py-2 bg-gray-100 text-gray-900 border-black border-2 hover:shadow-xl font-semibold transition-shadow duration-500"
        >
          or give a call to : 7240643002
        </a>
      </div>
      <div className="w-full items-center place-items-center h-auto bg-red-300 p-8 md:p-16 rounded-2xl grid grid-cols-1 gap-4">
        {images.map((image, index) => (
          <Image key={index} source={image} />
        ))}
      </div>
      <div className="text-center my-8">
        <h2 className="text-xl font-bold">
          Designed and developed by :{" "}
          <a
            href="http://www.8bitsys.in"
            className="text-pink-600 hover:text-pink-800"
          >
            8bitsys.in
          </a>
        </h2>
      </div>
    </div>
  );
}

Image.propTypes = {
  source: PropTypes.string,
};

export default Menu;
